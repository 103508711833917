<template>
  <div
    class="overlay"
    v-if="notification.active"
    @click="notification.active = false"
  ></div>
  <results-popup
    v-if="popupactive"
    @close="closePopup"
    :userdata="userdata"
    :chartDatas="chartDatas"
  ></results-popup>
  <div class="admin bg-slate py-10 min-h-screen">
    <div class="container m-auto">
      <div class="flex justify-between items-center">
        <div class="">
          <p>
            <font-awesome-icon
              icon="user-circle"
              class="bg-white p-2 rounded-full align-middle mr-1 bx-sdw"
            /><span class="font-bold align-middle">Admin</span>
          </p>
        </div>

        <div class="flex place-content-between">
          <div class="inline-block bg-white p-1 rounded-lg bx-sdw mr-3">
            <font-awesome-icon
              icon="search"
              class="align-middle px-3"
            ></font-awesome-icon>
            <input
              type="text"
              name=""
              id=""
              placeholder="Search User"
              class="align-middle w-28 md:w-52 text-xs outline-none"
            />
          </div>
          <div
            class="
              notification_btn
              rounded-full
              cursor-pointer
              bg-white
              p-2
              relative
            "
          >
            <font-awesome-icon
              icon="bell"
              class="align-middle bx-sdw"
              @click="notification.active = !notification.active"
            ></font-awesome-icon>
            <notification-panel
              v-if="notification.active"
              class="absolute right-1 mt-5 z-50"
            ></notification-panel>
          </div>
        </div>
      </div>

      <div class="buttons lg:mt-40 md:mt-28 mt-14">
        <div class="md:flex justify-between gap-2">
          <div
            class="
              rounded
              py-3
              px-6
              sm:w-2/6
              lg:w-80
              text-white
              bg-gradient-to-br
              from-tetiary
              to-primary
              mb-1
              md:mb-0
              cursor-pointer
            "
          >
            <div class="flex place-content-between lg:p-5">
              <div class="my-auto">
                <font-awesome-icon
                  icon="users"
                  class="align-middle pr-1 text-lg"
                ></font-awesome-icon>
                <p class="mt-2 lg:mt-3 font-light">Quiz Takers</p>
              </div>
              <span
                class="
                  float-right
                  font-bold
                  align-middle
                  text-lg
                  lg:text-xxl
                  my-auto
                "
                >{{ users.length }}</span
              >
            </div>
          </div>
          <div
            class="
              rounded
              py-3
              px-6
              sm:w-2/6
              lg:w-80
              text-white
              bg-gradient-to-br
              mb-1
              md:mb-0
              from-tetiary
              to-primary
              cursor-pointer
            "
          >
            <div class="flex place-content-between lg:p-5">
              <div class="my-auto">
                <font-awesome-icon
                  icon="book"
                  class="align-middle pr-1 text-lg"
                ></font-awesome-icon>
                <p class="mt-2 lg:mt-3 font-light">Quizzes</p>
              </div>
              <span
                class="
                  float-right
                  font-bold
                  align-middle
                  text-lg
                  lg:text-xxl
                  my-auto
                "
                >3</span
              >
            </div>
          </div>
          <div
            class="
              rounded
              py-3
              px-6
              sm:w-2/6
              lg:w-80
              text-white
              bg-gradient-to-br
              mb-1
              md:mb-0
              from-tetiary
              to-primary
              cursor-pointer
            "
            @click="showInviteForm"
          >
            <div class="flex place-content-between lg:p-5">
              <div class="my-auto">
                <!-- email svg -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="45"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <rect x="3" y="5" width="18" height="14" rx="2" ry="2"></rect>
                  <polyline points="3 7 12 13 21 7"></polyline>
                </svg>
                <p class="mt-2 lg:mt-3 font-light">Send Invite</p>
              </div>
              <font-awesome-icon
                icon="mail-forward"
                class="float-right clear-both text-lg lg:text-xxl my-auto align-middle font-bold"
              ></font-awesome-icon>
            </div>
          </div>
        </div>

        <div class="invite w-full mt-5" v-if="showform">
          <form action="" v-on:submit.prevent="sendInvite">
            <div class="flex w-full gap-2">
              <div class="w-4/5">
                <input
                  v-model="email"
                  type="email"
                  name="email"
                  id="email"
                  placeholder="Enter mail"
                  class="w-full px-5 py-2 rounded"
                />
              </div>
              <div class="w-1/5">
                <div
                  @click="sendInvite"
                  class="
                    px-5
                    py-2
                    bg-secondary
                    text-tetiary
                    font-semibold
                    rounded
                    text-center
                    cursor-pointer
                  "
                >
                  Send
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="users md:mt-20 mt-16">
        <div
          class="
            bg-white
            px-5
            py-8
            lg:px-10 lg:py-15
            overflow-auto
            bx-sdw
            rounded
          "
        >
          <table class="w-full text-xs whitespace-nowrap" border="0">
            <tr class="text-primary font-bold text-sm">
              <th>Name</th>
              <th>Email</th>
              <th>Organization</th>
              <th>Date Finished</th>
              <th>Action</th>
            </tr>
            <tr class="border-b" v-for="user in users" :key="user.id">
              <td>{{ user.name }}</td>
              <td>{{ user.email }}</td>
              <td>{{ user.organization }}</td>
              <td v-if="user.quiztaker_data != null">{{ formatDate(user.quiztaker_data.date_finished) }}</td>
              <td v-else>Not yet taken</td>
              <td><a href="#" @click="showResults(user)">See results</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationPanel from "../../components/admin/NotificationPanel.vue";
import ResultsPopup from "../../components/admin/ResultsPopup.vue";
// import users from "@/utils/users.json";

export default {
  components: { ResultsPopup, NotificationPanel },
  name: "AdminPage.vue",
  data() {
    return {
      popupactive: false,
      users: Array,
      userdata: null,
      chartDatas: [],
      showform: false,
      notification: {
        active: false,
        message: "",
        type: "",
      },
    };
  },
  methods: {
    sendInvite() {
      this.$store.dispatch("sendInvite", this.email);
    },
    showInviteForm() {
      this.showform = true;
    },
    showResults(userLoad) {
      this.chartDatas = [];
      this.userdata = userLoad;
      this.popupactive = true;
      document.body.style.overflow = "hidden";
      this.setChartDatas();
    },

    fetchUsers() {
      this.users = this.$store.state.user.users;
    },

    closePopup() {
      this.popupactive = false;
      document.body.style.overflow = "auto";
    },

    iniUserData() {
      // this.users = users.users;
    },
    // generate random rgba colors on white background
    random_rgba() {
      var o = Math.round,
        r = Math.random,
        s = 255;
      return (
        "rgba(" + o(r() * s) + "," + o(r() * s) + "," + o(r() * s) + ", 0.8)"
      );
    },

    setChartDatas() {
      let temp_arr = [];
      temp_arr.push(this.userdata.quiztaker_data.section_a);
      temp_arr.push(this.userdata.quiztaker_data.section_b);
      temp_arr.push(this.userdata.quiztaker_data.section_c);

      for (let i = 0; i < temp_arr.length; i++) {
        let key = Object.keys(temp_arr[i]);
        let value = Object.values(temp_arr[i]);
        let bg = this.random_rgba();
        this.chartDatas.push({
          labels: key,
          datasets: [
            {
              label: "Section A",
              data: value,
              backgroundColor: [bg, bg, bg, bg],
              borderColor: [bg, bg, bg, bg],
            },
          ],
        });
      }
    },

    // convert date to readable format
    formatDate(date) {
      let d = new Date(date);
      let year = d.getFullYear();
      let month = d.getMonth() + 1;
      let day = d.getDate();
      let hour = d.getHours();
      let min = d.getMinutes();
      let sec = d.getSeconds();
      return `${day}/${month}/${year} ${hour}:${min}:${sec}`;
    },

    // toggle notification panel if user clicks outside of it
    toggleNotificationPanel() {
      this.notification.active = !this.notification.active;
    },
  },
  mounted() {
    this.$store.dispatch("fetchUsers").then(() => {
      this.fetchUsers();
    });
  },
};
</script>

<style scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: rgba(0,0,0,0.1); */
  z-index: 45;
}

th {
  padding: 10px;
  text-align: left;
}
td {
  padding: 20px 10px;
  /* border: 1px solid red; */
  color: #777;
}

tr {
  border-color: #aaa;
}

a {
  text-decoration: underline;
  color: #b19f7a;
}
</style>