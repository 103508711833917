<template>
    <div class="panel py-5 bg-white rounded-md shadow-md overflow-y-auto">
        <div class="flex px-7 place-content-between">
            <h1 class="title font-normal text-base mb-5">Notifications</h1>
            <div class="read_reciept text-primary font-light text-xs" v-if="showReadAll && notifications.length > 0" @click="readAll">Mark as read</div>
        </div>
        <div class="messages px-7">
            <div v-if="notifications.length > 0" >
                <div class="border-b border-grey pb-3" v-for="item in notifications" :key="item.id" @click="markAsRead(item.id)">
                    <div class="flex notify-item h-14">
                        <div class="reciept rounded-full h-2 w-2 bg-secondary my-auto" v-if="!item.is_read"></div>
                        <div class="reciept rounded-full h-2 w-2 bg-grey my-auto" v-else></div>
                        <div class="message ml-2 my-auto">
                            <div class="text-xs font-light">
                                <p><span class="username font-bold">{{ item.user_name }}</span> {{ item.message }}</p>
                            </div>
                        </div>
                        <div class="icon my-auto mx-3">
                            <!-- exam success svg icon -->
                            <svg class="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.536-11.464a1 1 0 00-1.414 0L8 10.586 6.879 9.464a1 1 0 10-1.414 1.414l1.828 1.829a1 1 0 001.414 0l4.242-4.243a1 1 0 000-1.414z" clip-rule="evenodd" />
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <!-- spin for 5 seconds -->
                <div class="flex justify-center" v-if="loading">
                    <div class="text-center">
                        <p class="text-sm font-light">Loading...</p>
                    </div>
                </div>
                <div class="flex justify-center" v-else>
                    <div class="text-center">
                        <p class="text-sm font-light">No new notifications</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "NotificationPanel",
    data(){
        return {
            notifications: [],
            showReadAll: true,
            loading: true
        }
    },
    methods: {
        markAsRead(id){
            // mark all notifications as read
            console.log(id)
        },

        readAll(){
            // mark all notifications as read
            this.$store.dispatch('markAllNotificationsAsRead').then(() => {
                this.showReadAll = false
                this.notifications = []
            })
        }
    },
    mounted(){
        // fetch notifications from the server
        this.$store.dispatch('fetchNotifications').then((res) => {
            this.notifications = res.data
        })
        setTimeout(() => {
            this.loading = false
        }, 5000)
    }
}
</script>

<style scoped>
.panel{
    width: 400px;
    max-height: 480px;
}
</style>