<template>
  <div class="popup">
    <div
      @click="$emit('close')"
      class="
        z-20
        overlay
        fixed
        top-0
        bottom-0
        right-0
        left-0
        bg-opacity-20
        backdrop-blur-md
        h-full
        bg-black
      "
    ></div>
    <div
      class="w-full flex absolute justify-center top-0 bottom-0 items-center"
    >
      <div
        class="
          popup-content
          fixed
          z-40
          rounded-large
          lg:w-4/5
          w-11/12
          bg-white
          pl-4
          pr-1
          py-7
          md:pl-10 md:pr-1
        "
      >
        <div
          class="overflow-y-auto overflow-x-hidden h-full w-full p-2 lg:pr-5"
        >
          <div class="absolute right-8 top-8" @click="closepopup">
            <font-awesome-icon
              icon="close"
              class="cursor-pointer"
            ></font-awesome-icon>
          </div>
          <div class="mb-10">
            <p class="font-semibold text-base">{{ userdata.name }}</p>
            <p class="italic">{{ userdata.email }}</p>
            <p>{{ userdata.position }}</p>
          </div>

          <p class="text-md font-bold">Quiz Results</p>
          <p class="mb-2 font-light">
            Graphical Representation of the various traits
          </p>
          <div v-if="userdata.quiztaker_data">
            <div class="lg:flex lg:w-full gap-2">
              <div
                id="data-chart"
                class="p-3 mt-2 lg:w-1/3 max-w-md"
                v-for="item in chartDatas"
                :key="item.id"
              >
                <BarChart :chartData="item" ref="chart" />
              </div>
            </div>
            <div
              @click="downloadChart"
              class="text text-primary underline cursor-pointer"
            >
              Download Images
            </div>
          </div>
          <div v-else>
            <p class="text-base p-10">No data available</p>
          </div>

          <div class="mt-10">
            <form action="" v-on:submit.prevent="sendComment" class="my-10">
              <p class="mb-3 font-semibold">Your Analysis</p>
              <textarea
                name=""
                id=""
                class="border w-full rounded p-5 mb-2 outline-none"
                rows="2"
                v-model="comment"
              ></textarea>
              <div class="">
                <!-- <div class="file-upload bg-slate ">
                  <label for="file-upload" class="custom-file-upload h-full my-auto">
                    <div class="upload_btn my-auto py-1 px-2 w-11 h-full">
                      <img src="../../assets/svgs/clip.svg" alt="">
                    </div>
                  </label>
                  <input
                    id="file-upload"
                    type="file"
                    class="hidden"
                    @change="uploadFile"
                  />
                </div> -->
                <button
                  type="submit"
                  class="
                    float-right
                    clear-both
                    py-1
                    px-5
                    lg:py-3 lg:px-10
                    rounded
                    bg-tetiary
                    text-white
                  "
                >
                  Add Comment
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarChart from "../BarChart.vue";
// import clip from "../../assets/clip.svg";
// import jsPDF from "jspdf";
import html2canvas from "html2canvas";
export default {
  name: "ResultsPopup",
  props: ["userdata", "chartDatas"],
  components: { BarChart },
  data() {
    return {
      comment: "",
    };
  },
  methods: {
    closepopup() {
      this.$emit("close");
    },

    sendComment() {
      let data = {
        quiztaker_id: this.userdata.quiztaker_data.id,
        comment: this.comment,
      };
      this.$store.dispatch("sendComment", data).then((res) => {
        alert("Comment is successfully sent" + res);
        this.$emit("close");
      });
    },

    uploadFile(event) {
      let file = event.target.files[0];
      let data = new FormData();
      data.append("file", file);
      data.append("quiztaker_id", this.userdata.quiztaker_data.id);
      this.$store.dispatch("uploadFile", data).then((res) => {
        alert("File is successfully uploaded" + res);
        this.$emit("close");
      });
    },

    // download chart as image
    downloadChart() {
      let charts = document.querySelectorAll("#data-chart");
      charts.forEach((chart) => {
        html2canvas(chart).then((canvas) => {
          let img = canvas.toDataURL("image/png");
          let username = this.userdata.name;
          let filename = username + ".png";
          this.downloadURI(img, filename);
        });
      });
    },

    downloadURI(uri, name) {
      var link = document.createElement("a");
      link.download = name;
      link.href = uri;
      document.body.appendChild(link);
      link.click();
    },
  },
};
</script>


<style scoped>
.overlay {
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
}

.popup-content {
  max-width: 1350px;
  height: min(750px, 93%);
  z-index: 4000;
}
</style>